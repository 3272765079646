import React, { useState } from "react"
import { useTranslation, Trans } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"

export default function Contact() {
  const { t } = useTranslation()

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  })

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    // Replace this with your email sending logic
    console.log("Form submitted:", formData)
    alert(t("contact.form.successMessage")) // Assuming you have a success message key
  }

  return (
    <div className="w-full flex flex-col gap-6 justify-center items-center px-4 pt-4">
      <div className="flex flex-col gap-8 max-w-md">
        <p className="text-sm uppercase">
          <Trans i18nKey="contact.header">
            Want to learn more about how Arcanode can elevate your{" "}
            <strong>digital presence?</strong>
          </Trans>
        </p>
        <p className="text-sm uppercase">{t("contact.sub")}</p>
      </div>
      <div className="max-w-md w-full py-2">
        <form onSubmit={handleSubmit} className="max-w-md text-white">
          <div className="mb-4">
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder={t("contact.form.name")}
              className="shadow uppercase text-sm appearance-none bg-transparent border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          <div className="mb-4">
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder={t("contact.form.email")}
              className="shadow uppercase text-sm appearance-none bg-transparent border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
              required
            />
          </div>

          <div className="mb-4">
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder={t("contact.form.phone")}
              className="shadow uppercase text-sm appearance-none bg-transparent border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>

          <div className="mb-6">
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder={t("contact.form.message")}
              className="shadow uppercase text-sm appearance-none bg-transparent border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
              rows="5"
              required
            />
          </div>

          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="border hover:bg-gray-200 hover:text-gray-700 duration-500 text-white font-bold uppercase text-xs py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {t("contact.form.button")}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
